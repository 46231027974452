import { createIntl } from '@formatjs/intl';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SubjectIcon from '@mui/icons-material/Subject';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRouter } from 'next/router';
import * as React from 'react';
import { FormattedDate, FormattedMessage, useIntl, type IntlShape } from 'react-intl';
import { Flag } from './Flag';
import { useLocale } from 'components/LocalizationProvider';
import {
    ReportOverlay,
    ReportOverlayGrid,
    ReportOverlayGridActivePodcasts,
    ReportOverlayGridAverageWeeklyDownloads,
    ReportOverlayGridAverageWeeklyUsers,
    ReportOverlayGridMonthly,
    ReportOverlayGridNetwork,
    ReportOverlayGridNewEpisodes,
    ReportOverlayGridPublishersRepresented,
    ReportOverlayGridRank,
    ReportOverlayGridSalesRepresentative,
    ReportOverlayPodcast,
    ReportOverlayText,
} from 'components/ReportOverlay';
import { getDateFromDateString } from 'helpers/date';
import { getRankerPdfUrl, getRankerUrl } from 'helpers/urls';
import { useRankerJsonLoader } from 'hooks/useRankerJsonLoader';
import { type Locale, messagesByLocale } from 'models/locale';
import { type ReportSet, getReportSetTitle, regionTitles, regionIds, regionLabels } from 'models/report';
import { type RankerApi } from 'pages/api/ranker';
import {
    type ReportNetworkLatam,
    type ReportNetworkAu,
    type ReportNetworkCa,
    type ReportNetworkNl,
    type ReportNetworkNz,
    type ReportNetworkUsDownloads,
    type ReportNetworkUsUsers,
    type ReportPodcastAu,
    type ReportPodcastLatam,
    type ReportPodcastNl,
    type ReportPodcastNz,
    type ReportPodcastUs,
    type ReportSalesRepresentativeAu,
} from 'report/getReport';
import { type ReportPeriod, formatReportPeriodLabel } from 'report/reportPeriod';
import {
    type TableColumn,
    type TableColumnPdf,
    type TableColumnPdfCell,
    activePodcastsTableColumn,
    averageWeeklyDownloadsTableColumn,
    averageWeeklyUsersTableColumn,
    monthlyDownloadsTableColumn,
    monthlyListenersTableColumn,
    networkTableColumn,
    podcastNameTableColumn,
    podcastNameWithPublisherTableColumn,
    publishersTableColumn,
    publisherTableColumn,
    rankTableColumn,
    salesNetworkTableColumn,
    salesRepresentativeTableColumn,
    rankChangeTableColumn,
    newEpisodesTableColumn,
    categoryTableColumn,
} from 'report/tableColumns';
import {
    type TableFilter,
    categoryFilterDefinition,
    networkFilterDefinition,
    publisherFilterDefinition,
    salesNetworkFilterDefinition,
    salesRepresentativeFilterDefinition,
} from 'report/tableFilters';
import { theme } from 'styles/mui-theme';
import {
    ReportFormControl,
    ReportSelect,
    TritonTooltip,
    TritonTable,
    tritonTableRecordRow,
    tritonTableRecordRowAlternate,
    ReportSelectSecondary,
    ReportDownloadButton,
    ReportInputLabel,
    TritonDivider,
} from 'styles/styles';

export type ReportTablePdfData = {
    headers: string[];
    rows: TableColumnPdfCell[][];
};

export const ReportTableNetworkAu = createReportTablePartial<ReportNetworkAu>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [publisherTableColumn, { width: undefined }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [publisherTableColumn],
        [salesRepresentativeTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.publishers.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.publishers.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableNetworkCa = createReportTablePartial<ReportNetworkCa>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesNetworkTableColumn, { width: undefined }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesNetworkTableColumn],
        [salesRepresentativeTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

// TODO: Remove comments when we want to display salesRep Column
export const ReportTableNetworkLatam = createReportTablePartial<ReportNetworkLatam>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesNetworkTableColumn, { width: undefined }],
        // [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesNetworkTableColumn],
        // [salesRepresentativeTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            {/* <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid> */}
        </>
    ),
});

export const ReportTableNetworkNl = createReportTablePartial<ReportNetworkNl>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [networkTableColumn, { width: undefined }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [averageWeeklyUsersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [networkTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [averageWeeklyUsersTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridAverageWeeklyUsers row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableNetworkNz = createReportTablePartial<ReportNetworkNz>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [networkTableColumn, { width: undefined }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [networkTableColumn],
        [salesRepresentativeTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableNetworkUsDownloads = createReportTablePartial<ReportNetworkUsDownloads>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesNetworkTableColumn, { width: undefined }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesNetworkTableColumn],
        [salesRepresentativeTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableNetworkUsUsers = createReportTablePartial<ReportNetworkUsUsers>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesNetworkTableColumn, { width: undefined }],
        [averageWeeklyUsersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesNetworkTableColumn],
        [salesRepresentativeTableColumn],
        [averageWeeklyUsersTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyUsers row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTablePodcastAu = createReportTablePartial<ReportPodcastAu>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameWithPublisherTableColumn, { width: undefined }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '22%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [publisherTableColumn],
        [salesRepresentativeTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
        [newEpisodesTableColumn],
    ],
    filters: [categoryFilterDefinition, publisherFilterDefinition, salesRepresentativeFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
                <ReportOverlayGridNewEpisodes row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey="publisher"
                company={row.publishers}
                onFilterApply={onFilterApply}
            />
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={onFilterApply}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTablePodcastLatam = createReportTablePartial<ReportPodcastLatam>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameTableColumn, { width: undefined }],
        [publisherTableColumn, { hideWhenNarrow: true, width: '22%' }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 220 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [publisherTableColumn],
        [averageWeeklyDownloadsTableColumn],
    ],
    filters: [categoryFilterDefinition, publisherFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey="publisher"
                company={row.publishers}
                onFilterApply={onFilterApply}
            />
        </>
    ),
});

export const ReportTablePodcastNl = createReportTablePartial<ReportPodcastNl>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameTableColumn, { width: undefined }],
        [networkTableColumn, { hideWhenNarrow: true, width: '22%' }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [averageWeeklyUsersTableColumn, { hideWhenNarrow: true, width: 165 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [networkTableColumn],
        [categoryTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [averageWeeklyUsersTableColumn],
        [newEpisodesTableColumn],
    ],
    filters: [categoryFilterDefinition, networkFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridAverageWeeklyUsers row={row} />
                <ReportOverlayGridNewEpisodes row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey={null}
                company={row.publishers}
                onFilterApply={onFilterApply}
            />
            <ReportOverlayGrid>
                <ReportOverlayGridNetwork
                    networks={row.networks}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={onFilterApply}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTablePodcastNz = createReportTablePartial<ReportPodcastNz>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameTableColumn, { width: undefined }],
        [networkTableColumn, { hideWhenNarrow: true, width: '22%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [networkTableColumn],
        [salesRepresentativeTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
        [newEpisodesTableColumn],
    ],
    filters: [categoryFilterDefinition, networkFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
                <ReportOverlayGridNewEpisodes row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey="network"
                company={row.networks}
                onFilterApply={onFilterApply}
            />
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTablePodcastUs = createReportTablePartial<ReportPodcastUs>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameTableColumn, { width: undefined }],
        [salesNetworkTableColumn, { hideWhenNarrow: true, width: '20%' }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [salesNetworkTableColumn],
        [salesRepresentativeTableColumn],
        [newEpisodesTableColumn],
    ],
    filters: [categoryFilterDefinition, salesNetworkFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridNewEpisodes row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey="network"
                company={row.networks}
                onFilterApply={onFilterApply}
            />
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableSalesRepresentativeAu = createReportTablePartial<ReportSalesRepresentativeAu>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesRepresentativeTableColumn, { width: undefined }],
        [publishersTableColumn, { hideWhenNarrow: true, width: '30%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesRepresentativeTableColumn],
        [publishersTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.salesRepresentatives.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.salesRepresentatives.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridPublishersRepresented row={row} getCompanyUrl={(company) => company.salesUrl} />
            </ReportOverlayGrid>
        </>
    ),
});

export const getReportPeriodId = (period: ReportPeriod): string => `${period.year}/${period.month}`;

// we store the filter state as an array (for the MUI components) and a set (for our filter functions)
type FilterState = {
    array: string[];
    set: Set<string>;
};

export type OnFilterApply<FilterKey extends string> = (filterKey: FilterKey, filterValues: string[]) => void;

type CreateReportTableConfig<Ranker extends RankerApi, FilterKey extends string> = {
    columns: [TableColumn<Ranker['rows'][number]>, { hideWhenNarrow?: true; width: number | string | undefined }][];
    pdfColumns: [TableColumnPdf<Ranker['rows'][number]>][];
    filters: TableFilter<Ranker['rows'][number], FilterKey>[];
    Overlay: React.ComponentType<{
        row: Ranker['rows'][number];
        onFilterApply: OnFilterApply<FilterKey> | null;
    }>;
    getRowId: (row: Ranker['rows'][number]) => string;
};

export type ReportTableProps = {
    accessKey: string | null;
    reportSet: ReportSet;
    reportSetLinks: { reportSet: ReportSet; hasReportForCurrentPeriod: boolean }[];
    period: ReportPeriod;
    periodStartDateString: string;
    periodEndDateString: string;
    reportPeriods: ReportPeriod[];
};

function createReportTable<Ranker extends RankerApi, FilterKey extends string>({
    columns,
    pdfColumns,
    filters,
    Overlay,
    getRowId,
}: CreateReportTableConfig<Ranker, FilterKey>) {
    type State = {
        // use "partial" as we technically can't prove all the keys in the type were provided
        filterOptions: Partial<Record<FilterKey, string[]>>;
        filterStates: Partial<Record<FilterKey, FilterState>>;
        sortColumnIndex: number;
        sortDirection: 'asc' | 'desc';
    };

    const getFiltersState = (rows: Ranker['rows']) => {
        const result: Pick<State, 'filterOptions' | 'filterStates'> = {
            filterOptions: {},
            filterStates: {},
        };

        for (const filter of filters) {
            // sanity check the same filter hasn't been supplied twice, or a filter accidentally has the same key as another
            if (result.filterOptions[filter.filterKey] || result.filterStates[filter.filterKey]) {
                throw Error('duplicate filter state');
            }

            result.filterOptions[filter.filterKey] = Array.from(filter.getOptions(rows));

            result.filterStates[filter.filterKey] = {
                array: [],
                set: new Set(),
            };
        }

        return result;
    };

    const defaultState: State = {
        sortColumnIndex: 0,
        sortDirection: 'asc',
        ...getFiltersState([]),
    };

    const ReportTable = ({
        accessKey,
        period,
        periodStartDateString,
        periodEndDateString,
        reportSet,
        reportSetLinks,
        reportPeriods,
    }: ReportTableProps) => {
        const router = useRouter();

        const isFilterDisabled = useMediaQuery(theme.breakpoints.down('sm'));
        const isNarrow = useMediaQuery(theme.breakpoints.down('md'));

        const [state, setState] = React.useState(defaultState);

        const rankerJsonResult = useRankerJsonLoader<Ranker>({
            period,
            reportSet,
            accessKey,
        });

        const rows = React.useMemo(
            () => (rankerJsonResult.type === 'success' ? rankerJsonResult.report.rows : []),
            [rankerJsonResult],
        );

        // we hide rows using styles, to avoid adding/removing rows from the DOM while toggling the filters.
        // this means we can't rely on rowsFiltered.length to see how many rows have been filtered.
        // calculate a separate variable for that.
        const { rowsFiltered, rowsFilteredVisibleCount } = React.useMemo((): {
            rowsFiltered: {
                row: Ranker['rows'][number];
                // what is the index of this row, ignoring the hidden rows?
                visibleIndex: number;
                hidden: boolean;
            }[];
            rowsFilteredVisibleCount: number;
        } => {
            if (isFilterDisabled) {
                return {
                    rowsFiltered: rows.map((row, index) => ({ row, visibleIndex: index, hidden: false })),
                    rowsFilteredVisibleCount: rows.length,
                };
            }

            let rowsFilteredVisibleCount = 0;

            const rowsFiltered = rows.map((row) => {
                let hidden = false;

                for (const filter of filters) {
                    const filterState = state.filterStates[filter.filterKey];

                    if (!filterState) {
                        throw Error('missing filter key ' + filter.filterKey);
                    }

                    if (filterState.set.size === 0) {
                        continue;
                    }

                    if (!filter.filterFn(row, filterState.set)) {
                        hidden = true;

                        break;
                    }
                }

                const visibleIndex = rowsFilteredVisibleCount;

                if (!hidden) {
                    rowsFilteredVisibleCount++;
                }

                return {
                    row,
                    visibleIndex,
                    hidden,
                };
            });

            return {
                rowsFiltered,
                rowsFilteredVisibleCount,
            };
        }, [isFilterDisabled, rows, state.filterStates]);

        const rowsFilteredAndSorted = React.useMemo(() => {
            const rowsSorted = rowsFiltered.slice();

            const sortAscendingFn = columns[state.sortColumnIndex][0].sortAscendingFn;

            const reverseSort = state.sortDirection !== 'asc';
            const sortDirection = reverseSort ? -1 : 1;

            rowsSorted.sort((a, b) => sortAscendingFn(a.row, b.row) * sortDirection);

            return rowsSorted;
        }, [rowsFiltered, state.sortDirection, state.sortColumnIndex]);

        const resetAll = () => {
            setState((state) => {
                return {
                    ...state,
                    filterStates: defaultState.filterStates,
                    sortColumnIndex: defaultState.sortColumnIndex,
                    sortDirection: defaultState.sortDirection,
                };
            });
        };

        const resetFilter = () => {
            setState((state) => {
                return {
                    ...state,
                    filterStates: defaultState.filterStates,
                };
            });
        };

        const updateFilterState = (filterKey: FilterKey, value: string[]) => {
            setState((state) => {
                return {
                    ...state,
                    filterStates: {
                        ...state.filterStates,
                        [filterKey]: {
                            array: value,
                            set: new Set(value),
                        },
                    },
                };
            });
        };

        const updateSortIndex = (sortColumnIndex: number) => {
            if (sortColumnIndex === state.sortColumnIndex) {
                setState({
                    ...state,
                    sortDirection: state.sortDirection === 'asc' ? 'desc' : 'asc',
                });
            } else {
                setState({
                    ...state,
                    sortColumnIndex,
                    sortDirection: 'asc',
                });
            }
        };

        React.useEffect(() => {
            setState((state) => ({
                ...state,
                ...getFiltersState(rows),
            }));
        }, [rows]);

        const { recordOverlayState, showRecordOverlayForId, hideRecordOverlay } = useRecordOverlay(rows, getRowId);

        const intl = useIntl();
        const locale = useLocale();

        const allLabel = intl.formatMessage({
            defaultMessage: 'All',
            description: 'Option to filter everything in a list',
        });

        const clearFilterLabel = intl.formatMessage({
            defaultMessage: 'Clear filter',
            description: 'Button to clear existing filters',
        });

        const periodLabel = intl.formatMessage({
            defaultMessage: 'Period',
            description: 'Label for selecting period',
        });

        const regionLabel = intl.formatMessage({
            defaultMessage: 'Region',
            description: 'Label for selecting regions',
        });

        const rankerLabel = intl.formatMessage({
            defaultMessage: 'Ranker',
            description: 'Label for selecting rankers',
        });

        const reportSetTitle = React.useMemo(() => {
            return getReportSetTitle(reportSet);
        }, [reportSet]);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const pdfUrl = React.useMemo(() => {
            return getRankerPdfUrl({
                regionId: reportSet.regionId,
                slug: reportSet.slug,
                year: period.year,
                month: period.month,
                locale,
                accessKey: accessKey ?? undefined,
            });
        }, [accessKey, locale, period.month, period.year, reportSet.regionId, reportSet.slug]);

        return (
            <>
                <Grid container spacing={1} item xs={12} md={filters.length > 0 ? 6 : 12}>
                    <Grid item xs={12} md={4} zeroMinWidth>
                        <ReportFormControl fullWidth>
                            <ReportInputLabel>{regionLabel}</ReportInputLabel>
                            <ReportSelect
                                value={reportSet.regionId}
                                label={regionLabel}
                                onChange={(event) => {
                                    const newRegionId = event.target.value;

                                    void router.push(
                                        getRankerUrl({
                                            regionId: newRegionId,
                                        }),
                                    );
                                }}
                            >
                                {regionIds.map((regionId) => {
                                    return (
                                        <MenuItem key={regionId} value={regionId}>
                                            <span
                                                css={{
                                                    marginRight: 5,
                                                    lineHeight: 1,
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                <Flag
                                                    regionId={regionId}
                                                    title={regionLabels[regionId]}
                                                    width={4}
                                                    height={3}
                                                    css={{ width: 24, height: 'auto' }}
                                                />
                                            </span>
                                            {regionTitles[regionId]}
                                        </MenuItem>
                                    );
                                })}
                            </ReportSelect>
                        </ReportFormControl>
                    </Grid>
                    <Grid item xs={6} md={4} zeroMinWidth>
                        <ReportFormControl fullWidth>
                            <ReportInputLabel>{rankerLabel}</ReportInputLabel>
                            <ReportSelect
                                value={reportSet.slug}
                                label={rankerLabel}
                                onChange={(event) => {
                                    const reportSetOption = reportSetLinks.find(
                                        ({ reportSet }) => reportSet.slug === event.target.value,
                                    );

                                    if (!reportSetOption) {
                                        throw Error('reportSetOption not found');
                                    }

                                    resetAll();

                                    const url = getRankerUrl({
                                        regionId: reportSetOption.reportSet.regionId,
                                        slug: reportSetOption.reportSet.slug,
                                        period: reportSetOption.hasReportForCurrentPeriod ? period : undefined,
                                    });

                                    void router.push(url);
                                }}
                            >
                                {reportSetLinks.map(({ reportSet }) => (
                                    <MenuItem
                                        key={reportSet.slug}
                                        value={reportSet.slug}
                                        css={{
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        {getReportSetTitle(reportSet)}
                                    </MenuItem>
                                ))}
                            </ReportSelect>
                        </ReportFormControl>
                    </Grid>
                    <Grid item xs={6} md={4} zeroMinWidth>
                        <ReportFormControl fullWidth>
                            <ReportInputLabel>{periodLabel}</ReportInputLabel>
                            <ReportSelect
                                value={getReportPeriodId(period)}
                                label={periodLabel}
                                renderValue={() => (
                                    <div css={{ lineHeight: 1, marginTop: 4 }}>
                                        <div css={{ fontSize: '0.9em' }}>{formatReportPeriodLabel(period, locale)}</div>
                                        <div
                                            css={{
                                                marginTop: '0.2em',
                                                fontSize: '0.7em',
                                                color: theme.tritonColors.gray,
                                            }}
                                        >
                                            <FormattedDate
                                                value={getDateFromDateString(periodStartDateString)}
                                                month="short"
                                                day="numeric"
                                            />
                                            {` - `}
                                            <FormattedDate
                                                value={getDateFromDateString(periodEndDateString)}
                                                month="short"
                                                day="numeric"
                                            />
                                        </div>
                                    </div>
                                )}
                                onChange={(event) => {
                                    const newPeriod = reportPeriods.find(
                                        (period) => getReportPeriodId(period) === event.target.value,
                                    );

                                    if (!newPeriod) {
                                        throw Error('ReportPeriod not found');
                                    }

                                    resetAll();

                                    void router.push(
                                        getRankerUrl({
                                            regionId: reportSet.regionId,
                                            slug: reportSet.slug,
                                            period: newPeriod,
                                        }),
                                    );
                                }}
                            >
                                {reportPeriods.map((period) => {
                                    const periodId = getReportPeriodId(period);

                                    return (
                                        <MenuItem key={periodId} value={periodId}>
                                            {formatReportPeriodLabel(period, locale)}
                                        </MenuItem>
                                    );
                                })}
                            </ReportSelect>
                        </ReportFormControl>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container spacing={{ sm: 0.5, md: 1 }} justifyContent={filters.length > 0 ? '' : 'flex-end'}>
                        {!isFilterDisabled && filters.length > 0 ? (
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={1} justifyContent="" alignItems="center">
                                    <Grid item xs="auto">
                                        <Typography variant="subtitle2" component="h1">
                                            <FormattedMessage
                                                defaultMessage="Filters"
                                                description="Label for report table filters"
                                            />
                                            :
                                        </Typography>
                                    </Grid>
                                    {filters.map((filter) => {
                                        const filterOptions = state.filterOptions[filter.filterKey];
                                        const filterState = state.filterStates[filter.filterKey];

                                        if (!filterOptions || !filterState) {
                                            throw Error('missing filterOptions/filterState');
                                        }

                                        const filterEnabled = filterState.array.length > 0;

                                        return (
                                            <Grid key={filter.filterKey} item xs zeroMinWidth>
                                                <ReportFormControl fullWidth selected={filterEnabled}>
                                                    <ReportInputLabel shrink>
                                                        <FormattedMessage {...filter.messageDescriptorHeader} />
                                                    </ReportInputLabel>
                                                    <ReportSelectSecondary
                                                        value={filterState.array}
                                                        label={<FormattedMessage {...filter.messageDescriptorHeader} />}
                                                        multiple
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (selected.length === 0) {
                                                                return allLabel;
                                                            }

                                                            if (selected.length === 1) {
                                                                return Array.from(selected)[0];
                                                            }

                                                            return intl.formatMessage(
                                                                filter.messageDescriptorMultipleValues,
                                                                {
                                                                    count: selected.length,
                                                                },
                                                            );
                                                        }}
                                                        endAdornment={
                                                            filterEnabled ? (
                                                                <TritonTooltip title={clearFilterLabel}>
                                                                    <IconButton
                                                                        color="inherit"
                                                                        css={{ position: 'absolute', right: 20 }}
                                                                        onClick={() => {
                                                                            updateFilterState(filter.filterKey, []);
                                                                        }}
                                                                    >
                                                                        <ClearIcon />
                                                                    </IconButton>
                                                                </TritonTooltip>
                                                            ) : null
                                                        }
                                                        onChange={(event) => {
                                                            if (typeof event.target.value === 'string') {
                                                                throw Error('ReportSelect onChange passed string');
                                                            }

                                                            updateFilterState(filter.filterKey, event.target.value);
                                                        }}
                                                    >
                                                        {filterOptions.map((option) => (
                                                            <MenuItem key={option} value={option} dense>
                                                                <Checkbox
                                                                    sx={{
                                                                        paddingLeft: 1,
                                                                        paddingTop: 0.5,
                                                                        paddingRight: 1,
                                                                        paddingBottom: 0.5,
                                                                    }}
                                                                    checked={filterState.set.has(option)}
                                                                />
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </ReportSelectSecondary>
                                                </ReportFormControl>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        ) : null}
                        <Grid item xs md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                <Grid item width="100%">
                                    <ReportDownloadButton
                                        sx={{ height: '56px', width: '100%' }}
                                        href={pdfUrl}
                                        target="_blank"
                                        LinkComponent={Link}
                                    >
                                        <FileDownloadIcon sx={{ mr: 1 }} />
                                        <FormattedMessage
                                            defaultMessage="Export as PDF"
                                            description="Export PDF button in table header"
                                        />
                                    </ReportDownloadButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    flexGrow={1}
                    sx={{
                        display: 'flex',
                        minHeight: 320,
                        height: 0, // fixes table height to be 100% in flex container
                    }}
                >
                    <TableContainer sx={{ background: theme.palette.grey[200] }}>
                        <TritonTable stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map(([column, columnConfig], index) => {
                                        const isSortingColumn = index === state.sortColumnIndex;

                                        return (
                                            <TableCell
                                                key={index}
                                                align={column.align}
                                                style={{
                                                    display:
                                                        columnConfig.hideWhenNarrow && isNarrow ? 'none' : undefined,
                                                    width: columnConfig.width,
                                                }}
                                                sortDirection={isSortingColumn ? state.sortDirection : false}
                                            >
                                                <TableSortLabel
                                                    active={isSortingColumn}
                                                    direction={isSortingColumn ? state.sortDirection : undefined}
                                                    onClick={() => {
                                                        updateSortIndex(index);
                                                    }}
                                                >
                                                    <FormattedMessage {...column.headerMessageDescriptor} />
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rankerJsonResult.type === 'loading' ? (
                                    <TableStatusRow>
                                        <CircularProgress />
                                    </TableStatusRow>
                                ) : rankerJsonResult.type === 'notFound' ? (
                                    <TableStatusRow>
                                        <TableDataNotFound />
                                    </TableStatusRow>
                                ) : rankerJsonResult.type === 'error' ? (
                                    <TableStatusRow>
                                        <TableDataError />
                                    </TableStatusRow>
                                ) : rowsFilteredVisibleCount === 0 ? (
                                    <TableStatusRow>
                                        <TableDataEmpty
                                            onClearFilters={() => {
                                                resetFilter();
                                            }}
                                        />
                                    </TableStatusRow>
                                ) : (
                                    rowsFilteredAndSorted.map(({ row, visibleIndex, hidden }) => {
                                        const rowId = getRowId(row);

                                        return (
                                            <TableRow
                                                key={rowId}
                                                css={
                                                    visibleIndex % 2
                                                        ? tritonTableRecordRow
                                                        : tritonTableRecordRowAlternate
                                                }
                                                style={hidden ? { display: 'none' } : undefined}
                                                tabIndex={-1}
                                                onClick={() => {
                                                    showRecordOverlayForId(rowId);
                                                }}
                                            >
                                                {columns.map(([column, columnConfig], index) => {
                                                    return (
                                                        <TableCell
                                                            key={index}
                                                            align={column.align}
                                                            style={{
                                                                display:
                                                                    columnConfig.hideWhenNarrow && isNarrow
                                                                        ? 'none'
                                                                        : undefined,
                                                                width: columnConfig.width,
                                                            }}
                                                        >
                                                            {column.renderCell(row)}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                )}
                            </TableBody>
                        </TritonTable>
                    </TableContainer>
                </Grid>
                <Grid container item justifyContent="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Grid item xs={12}>
                        <TritonDivider />
                        <Grid container>
                            <Grid item xs={6}>
                                <Button href="#disclaimer" sx={{ width: '100%' }}>
                                    <SubjectIcon fontSize="small" />
                                    <FormattedMessage
                                        defaultMessage="Disclaimers"
                                        description="Link to the disclaimers section"
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button href={pdfUrl} target="_blank" LinkComponent={Link} sx={{ width: '100%' }}>
                                    <FileDownloadIcon fontSize="small" />
                                    <FormattedMessage
                                        defaultMessage="Export as PDF"
                                        description="Export PDF button in mobile footer"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    open={recordOverlayState.type === 'visible'}
                    maxWidth="md"
                    fullWidth
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: 0,
                        },
                    }}
                    onClose={() => {
                        hideRecordOverlay();
                    }}
                >
                    {recordOverlayState.type !== 'empty' && (
                        <ReportOverlay
                            reportTitle={regionTitles[reportSet.regionId]}
                            reportSetTitle={reportSetTitle}
                            periodLabel={formatReportPeriodLabel(period, locale)}
                            onClose={() => {
                                hideRecordOverlay();
                            }}
                        >
                            <Overlay
                                row={recordOverlayState.row}
                                onFilterApply={
                                    !isFilterDisabled
                                        ? (filterKey, filterValues) => {
                                              updateFilterState(filterKey, filterValues);

                                              hideRecordOverlay();
                                          }
                                        : null
                                }
                            />
                        </ReportOverlay>
                    )}
                </Dialog>
            </>
        );
    };

    const getPdfData = (report: Ranker, Locale: Locale): ReportTablePdfData => {
        const intl = createIntl({
            locale: Locale,
            messages: messagesByLocale[Locale],
        });

        const headers = pdfColumns.map(([column]) => {
            return intl.formatMessage(column.headerMessageDescriptor);
        });

        const rows = report.rows.map((row) => {
            return pdfColumns.map(([column]) => {
                // createIntl creates a slightly different IntlShape, but it's not exported
                return column.renderCellPdf(row, intl as IntlShape);
            });
        });

        return {
            headers,
            rows,
        };
    };

    return {
        getPdfData,
        Component: ReportTable,
    };
}

// we want to call createReportTable with a Row generic, but let the FilterKey be inferred.
// TypeScript doesn't support this, so we use two functions.
// the outer function sets the Row generic and the inner function uses inference.
// createReportTablePartial<{ rowExample: number }>()(options)
function createReportTablePartial<Report extends RankerApi>() {
    return function wrapper<FilterKey extends string = never>(options: CreateReportTableConfig<Report, FilterKey>) {
        return createReportTable(options);
    };
}

// use the location hash as the source of truth to indicate whether the overlay is shown,
// taking into account the hash may not match a record.
function useRecordOverlay<Row>(rows: Row[], getRowId: (row: Row) => string) {
    // the current ID extracted from the hash
    const [currentHashId, setCurrentHashId] = React.useState('');

    React.useEffect(() => {
        const onHashChanged = () => {
            const currentHashId = window.decodeURIComponent(window.location.hash.substring(1));

            setCurrentHashId(currentHashId);
        };

        // calculate on initial render
        onHashChanged();

        window.addEventListener('hashchange', onHashChanged);

        return () => {
            window.removeEventListener('hashchange', onHashChanged);
        };
    }, []);

    // we want to store the record while hidden so we can still show it during the fade out animation
    const [overlayState, setOverlayState] = React.useState<
        { type: 'empty' } | { type: 'visible'; row: Row } | { type: 'hidden'; row: Row }
    >({ type: 'empty' });

    React.useEffect(() => {
        const row = currentHashId ? rows.find((row) => getRowId(row) === currentHashId) : undefined;

        if (row) {
            setOverlayState({
                type: 'visible',
                row,
            });
        } else {
            setOverlayState((state) => {
                switch (state.type) {
                    case 'empty':
                    case 'hidden': {
                        return state;
                    }

                    case 'visible': {
                        return {
                            type: 'hidden',
                            row: state.row,
                        };
                    }
                }
            });
        }
    }, [currentHashId, rows, getRowId]);

    const router = useRouter();

    return {
        recordOverlayState: overlayState,
        showRecordOverlayForId: (recordId: string) => {
            window.location.hash = `#${recordId}`;
        },
        hideRecordOverlay: () => {
            // clear the hash to trigger the hashchange event
            window.location.hash = '';

            // clearing the hash leaves an empty # in the URL.
            // to remove this, we also strip the hash out of the URL and redirect the page to that URL.
            const pathWithoutHash = router.asPath.split('#')[0];

            if (!pathWithoutHash) {
                return;
            }

            // use shallow true to avoid getServerSideProps being run again.
            // we know data does not need to be refetched.
            void router.replace(pathWithoutHash, undefined, { shallow: true });
        },
    };
}

export const TableDataEmpty = ({ onClearFilters }: { onClearFilters: () => void }) => {
    return (
        <Stack spacing={2}>
            <FormatListNumberedIcon
                css={{
                    fontSize: 100,
                    color: grey[300],
                    margin: '0 auto',
                }}
            />
            <Typography variant="h5" color={grey[700]}>
                <FormattedMessage defaultMessage="No data to display" description="Table missing data heading" />
            </Typography>
            <Typography color={grey[500]}>
                <FormattedMessage defaultMessage="Try adjusting or" description="Table missing data description" />{' '}
                <Link component="button" variant="body1" sx={{ verticalAlign: 'bottom' }} onClick={onClearFilters}>
                    <FormattedMessage
                        defaultMessage="clearing your filters"
                        description="Table missing data call to action to clear filters"
                    />
                </Link>
            </Typography>
        </Stack>
    );
};

const TableDataError = () => {
    const router = useRouter();

    return (
        <Stack spacing={2}>
            <ErrorIcon
                css={{
                    fontSize: 100,
                    color: grey[300],
                    margin: '0 auto',
                }}
            />
            <Typography variant="h5" color={grey[700]}>
                <FormattedMessage defaultMessage="Error retrieving report" description="Table data error heading" />
            </Typography>
            <Typography color={grey[500]}>
                <Link
                    component="button"
                    variant="body1"
                    sx={{ verticalAlign: 'bottom' }}
                    onClick={() => {
                        router.reload();
                    }}
                >
                    <FormattedMessage defaultMessage="Retry" description="Table data error call to action to retry" />
                </Link>
            </Typography>
        </Stack>
    );
};

const TableDataNotFound = () => {
    return (
        <Stack spacing={2}>
            <ErrorIcon
                css={{
                    fontSize: 100,
                    color: grey[300],
                    margin: '0 auto',
                }}
            />
            <Typography variant="h5" color={grey[700]}>
                <FormattedMessage defaultMessage="Report not found" description="Table data not found heading" />
            </Typography>
            <Typography color={grey[500]}>
                <FormattedMessage
                    defaultMessage="The requested report could not be loaded. Please check the URL is valid."
                    description="Table data not found description"
                />
            </Typography>
        </Stack>
    );
};

const TableStatusRow = ({ children }: React.PropsWithChildren<unknown>) => (
    <TableRow>
        <TableCell colSpan={99}>
            <Box css={{ paddingTop: 20, paddingBottom: 20 }} textAlign="center">
                {children}
            </Box>
        </TableCell>
    </TableRow>
);
